<template>
  <div>
    <label class="flex items-center">
      <span class="flex items-center cursor-pointer relative">
        <input v-model="_value" type="checkbox" class="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded hover:shadow-md border border-2 checked:bg-action checked:border-action" id="check3" />
        <span class="absolute text-action opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" stroke-width="1">
            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
          </svg>
        </span>
      </span>
      <label v-if="label || $slots.default" class="text-sm font-medium text-secondary ml-2">
        <slot>
          {{ label }}
        </slot>
      </label>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BCheckbox',
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style>
  .b-checkbox:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
</style>
